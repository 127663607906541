import React from 'react';
import { NextPageWithLayout } from '~/types/layout';
import { getAuthLayout } from '~/categories/auth/components/layouts/getAuthLayout';
import { useAuthPageContext } from '~/categories/auth/hooks/common/useAuthPageContext';
import { LoginPage } from '~/categories/auth/components/pages/LoginPage';
import { externalLinks } from '~/constants/externalLinks';

const Page: NextPageWithLayout = () => {
  const pageContext = useAuthPageContext();

  return <LoginPage {...pageContext} />;
};

Page.Layout = getAuthLayout({
  getLayoutContents: () => {
    return {
      showHeaderLogo: true,
      hiddenFooterLogo: true,
      themeColorBg: true,
      footerLink: {
        title: 'ログインについてのお問い合わせはこちら',
        href: externalLinks.inquiryAboutLogin,
      },
    };
  },
});

Page.config = {
  pageTitle: 'ログイン',
};

export default Page;
