import useSWR from 'swr';
import { IAuthApiClient } from '~/categories/auth/apis/authApiClient';
import { swrKeys } from '~/constants/swrKeys';

type IUseConfirmAuthenticated = (
  apiClient: IAuthApiClient,
  callback: (args: { authenticated: boolean }) => void
) => void;

export const useConfirmAuthenticated: IUseConfirmAuthenticated = (apiClient, callback) => {
  useSWR(swrKeys.confirmAuthenticated, apiClient.confirmAuthenticated, {
    errorRetryCount: 0,
    onError: () => {},
    onSuccess: (result) => {
      callback({ authenticated: result });
    },
  });
};
