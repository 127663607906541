import React from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '~/components/data-entries/Input';
import { Label } from '~/components/data-entries/Label';
import * as styles from './index.styles';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '~/components/buttons/Button';
import Link from 'next/link';
import { authPathBuilder } from '~/categories/auth/constants/authPathBuilder';
import Alert from '~/assets/icon/alert.svg';

type FormValue = {
  email: string;
  password: string;
};

const schema = z.object({
  email: z.string().min(1, { message: 'メールアドレスを入力してください' }),
  password: z.string().min(1, { message: 'パスワードを入力してください' }),
});

type FormValues = z.infer<typeof schema>;

export type Props = {
  onSubmit: (values: FormValue) => void;
  sending: boolean;
  errorMessages?: string[];
};

const MESSAGE = {
  LOGIN_ID: {
    LABEL: 'メールアドレス',
  },
  PASSWORD: {
    LABEL: 'パスワード',
  },
  BUTTON: 'ログイン',
} as const;

export const LoginForm: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const onSubmitHandler = handleSubmit((formData) => {
    if (props.sending) return;
    props.onSubmit(formData);
  });

  return (
    <div css={styles.container}>
      <form onSubmit={onSubmitHandler} css={styles.inputFields}>
        <div>
          <Label htmlFor="email" label={MESSAGE.LOGIN_ID.LABEL} />
          <Input
            id="email"
            type="text"
            error={!!props.errorMessages}
            errorMessage={errors.email?.message}
            {...register('email')}
          />
        </div>

        <div>
          <Label htmlFor="password" label={MESSAGE.PASSWORD.LABEL} />
          <Input
            id="password"
            type="password"
            error={!!props.errorMessages}
            errorMessage={errors.password?.message}
            {...register('password')}
          />
        </div>

        <div css={styles.loginButton}>
          <Button
            design="primary"
            display="block"
            buttonHeight={48}
            type="submit"
            fontSize="m"
            loading={props.sending}
          >
            {MESSAGE.BUTTON}
          </Button>

          {props.errorMessages && (
            <div css={styles.errorMessage.container}>
              <img css={styles.errorMessage.icon} src={Alert} alt={''} />
              {props.errorMessages.join('')}
            </div>
          )}

          <Link href={authPathBuilder().resetPassword()} passHref>
            <a>パスワードを忘れた方はこちら</a>
          </Link>
        </div>
      </form>
    </div>
  );
};
